'use client';

import { usePathname } from 'next/navigation';
import { cn } from '@/lib/utils';
import { NavigationLink } from './links';
const classes = {
  active: {
    base: 'border-b-4 border-transparent ',
    desktop: 'border-white',
    mobile: 'border-secondary'
  },
  link: {
    base: 'flex gap-2 text-lg font-[800] uppercase font-heading no-underline border-b-4 border-transparent hover:border-b-4',
    desktop: 'hover:text-white border-transparent mt-1',
    mobile: 'pb-1 hover:text-secondary border-text-secondary'
  },
  list: {
    desktop: 'flex gap-10',
    mobile: 'flex flex-col gap-10'
  }
};
interface NavigationListProps {
  isDesktop: boolean;
  links: Readonly<NavigationLink[]>;
}
export const NavigationList = ({
  links,
  isDesktop
}: NavigationListProps) => {
  const pathname = usePathname();
  return <nav>
      <ul className={cn({
      [classes.list.desktop]: isDesktop === true,
      [classes.list.mobile]: isDesktop === false
    })}>
        {links.map(link => {
        const isActive = link.isActive(pathname);
        return <li key={link.href}>
              <a className={cn(classes.link.base, {
            [classes.link.desktop]: isDesktop,
            [classes.link.mobile]: !isDesktop,
            [classes.active.base]: isActive,
            [classes.active.desktop]: isActive && isDesktop,
            [classes.active.mobile]: isActive && !isDesktop
          })} href={link.href}>
                {!isDesktop && link.icon}
                {link.label}
              </a>

              {!isDesktop && link.description && <p className="mt-4 text-gray-500 font-mono">
                  {link.description}
                </p>}
            </li>;
      })}
      </ul>
    </nav>;
};