import logger from './logger';

export const getNicknameInitials = (nickname?: string | null) => {
  if (!nickname) {
    return null;
  }

  try {
    const nameIndex = nickname?.indexOf('.');
    const firstName = nickname[0];
    const lastName = nickname.charAt(nameIndex + 1);

    return `${firstName}${lastName}`;
  } catch (err) {
    logger.error(err);
  }

  return null;
};
