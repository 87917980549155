'use client';

import { UserCog2 } from 'lucide-react';
import { Skeleton } from '@/components/ui/skeleton';
export function UserOrLoginSkeleton() {
  return <div>
      <div className="relative">
        <div className="relative z-10 h-10 w-10 rounded-full flex items-center justify-center animate-spin">
          <div className="absolute inset-0 rounded-full border-4 border-blue-400"></div>
          <div className="absolute inset-0 rounded-full border-4 border-t-0 border-blue-500"></div>
        </div>
        <Skeleton className="absolute left-0 top-0 flex bg-secondary-readable/[0.5] items-center justify-center rounded-full w-[40px] h-[40px]">
          <UserCog2 className="text-white h-6 w-6" />
        </Skeleton>
      </div>
    </div>;
}