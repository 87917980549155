'use client';

import Image from 'next/image';
import Link from 'next/link';
import { ContentfulGlobalSettings } from '@/lib/types/contentful/ContentfulGlobalSettings';
import { MainMenu } from './MainMenu';
import { UserOrLogin } from './UserOrLogin';
interface HeaderProps extends Pick<ContentfulGlobalSettings, 'searchLinkText' | 'aboutLinkText' | 'logo'> {}
export const Header = ({
  logo,
  ...props
}: HeaderProps) => {
  return <header className="flex bg-secondary text-white h-[100px]">
      <div className="container px-4 sm:px-8 flex justify-between items-center">
        <Link prefetch={false} className="no-underline hover:text-white" href="/">
          <div className="flex items-center gap-5">
            <Image className="object-contain" alt="Smart Data Hub Logo" height={58} width={58} src={`${logo.url}?fm=webp`} />

            <h1 className="leading-6 pr-5 text-lg md:text-xl lg:text-2xl">
              Smart Data Hub
            </h1>
          </div>
        </Link>
        <div className="flex items-center gap-2 sm:gap-5 md:gap-10">
          <MainMenu {...props} />

          <UserOrLogin />
        </div>
      </div>
    </header>;
};