'use client';

import { useUser } from '@auth0/nextjs-auth0/client';
import { Unlock } from 'lucide-react';
import { useRouter } from 'next/navigation';
import { useCallback } from 'react';
import { Button } from '@/components/ui/button';
import { UserOrLoginSkeleton } from './Skeleton';
import { User } from './User';
export function UserOrLogin() {
  const router = useRouter();
  const {
    user,
    isLoading
  } = useUser();
  const login = useCallback(() => router.push(`/api/auth/login?returnTo=${window.location.href}`), [router]);
  if (isLoading) {
    return <UserOrLoginSkeleton />;
  }
  if (!user) {
    return <div>
        <Button onClick={login} variant="ghost">
          <Unlock />
        </Button>
      </div>;
  }
  return <User user={user} />;
}