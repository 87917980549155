'use client';

import { Building2, FolderSearch } from 'lucide-react';
import { ROUTE_DESCRIPTIONS, ROUTE_HREFS } from '@/lib/navigation';
export interface NavigationLink {
  icon: React.ReactNode;
  href: string;
  description?: string;
  label: string;
  isActive: (pathname: string) => boolean;
}
export const createNavigationLinks = (searchLinkText: string, aboutLinkText: string): NavigationLink[] => [{
  icon: <FolderSearch />,
  href: ROUTE_HREFS['search'],
  description: ROUTE_DESCRIPTIONS['search'],
  label: searchLinkText,
  isActive: (pathname: string) => pathname.startsWith('/search') || pathname.startsWith('/dataset') || pathname === '/'
}, {
  icon: <Building2 />,
  href: ROUTE_HREFS['about'],
  description: ROUTE_DESCRIPTIONS['about'],
  label: aboutLinkText,
  isActive: (pathname: string) => pathname.startsWith('/about')
}] as const;