'use client';

import { UserProvider } from '@auth0/nextjs-auth0/client';
import { SWRConfig } from 'swr';
import { Toaster } from '@/components/ui/sonner';
import { fetcher } from '@/lib/fetcher';
import { ContentfulGlobalSettings } from '@/lib/types/contentful/ContentfulGlobalSettings';
import { Footer } from './Footer';
import { Header } from './Header';
export type ContentHeader = Pick<ContentfulGlobalSettings, 'searchLinkText' | 'aboutLinkText' | 'logo'>;
export type ContentFooter = Pick<ContentfulGlobalSettings, 'sys' | 'siteName' | 'footerEmail' | 'footerOtherLinks' | 'footerPhone' | 'footerSocialLinks' | 'footerText' | 'eula' | 'privacyPolicy'>;
interface RootContentProps {
  children: React.ReactNode;
  header: ContentHeader;
  footer: ContentFooter;
}
export function RootContent({
  header,
  footer,
  children
}: RootContentProps) {
  return <SWRConfig value={{
    refreshWhenHidden: false,
    refreshWhenOffline: false,
    shouldRetryOnError: false,
    revalidateOnFocus: false,
    fetcher
  }}>
      <UserProvider>
        <Header {...header} />
        <main className="grow">{children}</main>
        <Footer {...footer} />
        <Toaster />
      </UserProvider>
    </SWRConfig>;
}