'use client';

import { MenuIcon, X } from 'lucide-react';
import { useMemo } from 'react';
import { Drawer, DrawerClose, DrawerContent, DrawerFooter, DrawerHeader, DrawerTitle, DrawerTrigger } from '@/components/ui/drawer';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ContentfulGlobalSettings } from '@/lib/types/contentful/ContentfulGlobalSettings';
import { NavigationList } from './NavigationList';
import { createNavigationLinks } from './links';
import type { NavigationLink } from './links';
interface MainMenuProps extends Pick<ContentfulGlobalSettings, 'searchLinkText' | 'aboutLinkText'> {}
export const MainMenu = ({
  searchLinkText,
  aboutLinkText
}: MainMenuProps) => {
  const isDesktop = useMediaQuery('(min-width: 960px)');
  const links: Readonly<NavigationLink[]> = useMemo(() => createNavigationLinks(searchLinkText, aboutLinkText), [searchLinkText, aboutLinkText]);
  const linkList = useMemo(() => <NavigationList links={links} isDesktop={isDesktop !== false} />, [links, isDesktop]);
  if (isDesktop === null) {
    return null;
  }
  if (isDesktop) {
    return linkList;
  }

  // wrap into drawer in mobile
  return <>
      <Drawer direction="right">
        <DrawerTrigger>
          <MenuIcon />
        </DrawerTrigger>
        <DrawerContent>
          <DrawerHeader>
            <DrawerTitle>Pages</DrawerTitle>
          </DrawerHeader>
          {linkList}
          <DrawerFooter>
            <DrawerClose className="text-center">
              <X className="text-primary" />
            </DrawerClose>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>;
};