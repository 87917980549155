import logger from './logger';

const errorHandler = (error: unknown) => {
  logger.error('Error', error);
  throw error;
};

export async function fetcher(url: string, init?: RequestInit) {
  try {
    const response = await fetch(url, init);
    return await response.json();
  } catch (error) {
    errorHandler(error);
  }
}

export async function deleteFetcher<T>(url: string, data?: T) {
  try {
    return await fetcher(url, {
      method: 'DELETE',
      body: data ? JSON.stringify(data) : undefined,
    });
  } catch (error) {
    errorHandler(error);
  }
}

export async function postFetcher<TData>(url: string, data: TData) {
  try {
    return await fetcher(url, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  } catch (error) {
    errorHandler(error);
  }
}
