'use client';

import { AlertTriangle, Check, CheckIcon, InfoIcon } from 'lucide-react';
import { useTheme } from 'next-themes';
import { Toaster as Sonner } from 'sonner';
type ToasterProps = React.ComponentProps<typeof Sonner>;
const Toaster = ({
  ...props
}: ToasterProps) => {
  const {
    theme = 'system'
  } = useTheme();
  return <Sonner icons={{
    success: <CheckIcon className="text-primary" />,
    error: <AlertTriangle className="text-primary" />,
    info: <InfoIcon className="text-primary" />
  }} theme={theme as ToasterProps['theme']} className="toaster group" toastOptions={{
    classNames: {
      toast: 'group gap-2 toast group-[.toaster]:bg-background group-[.toaster]:text-zinc-950 group-[.toaster]:border-zinc-200 group-[.toaster]:shadow-lg',
      title: 'ml-2 font-mono text-xs',
      description: 'font-mono text-xs',
      actionButton: 'group-[.toast]:bg-zinc-900 group-[.toast]:text-zinc-50',
      cancelButton: 'group-[.toast]:bg-zinc-100 group-[.toast]:text-zinc-500'
    }
  }} {...props} />;
};
export { Toaster };