'use client';

import { cx } from 'class-variance-authority';
import { AlertTriangle } from 'lucide-react';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { Button } from '@/components/ui/button';
const DEFAULT_ERROR_TITLE = 'Technical issues';
const DEFAULT_ERROR_MESSAGE = 'We are experiencing technical issues with this feature, please try again later.';
const classes = {
  background: 'bg-background'
};
export const ErrorAlert = ({
  title = DEFAULT_ERROR_TITLE,
  message = DEFAULT_ERROR_MESSAGE,
  background = false,
  showReload = true
}: {
  title?: string;
  message?: React.ReactNode;
  background?: boolean;
  showReload?: boolean;
}) => {
  return <Alert variant="destructive" className={cx('space-y-1', {
    [classes.background]: background
  })}>
      <AlertTriangle />
      {title && <AlertTitle>{title}</AlertTitle>}
      <AlertDescription>
        <div className="space-y-5">
          <div>{message}</div>

          {showReload && <Button size="sm" variant="destructive" onClick={() => window.location.reload()}>
              Reload
            </Button>}
        </div>
      </AlertDescription>
    </Alert>;
};