'use client';

import * as React from 'react';
import { Drawer as DrawerPrimitive } from 'vaul';
import { cn } from '@/lib/utils';
const DrawerContext = React.createContext<{
  direction?: 'top' | 'bottom' | 'left' | 'right';
}>({});
const useDrawerContext = () => React.useContext(DrawerContext);
const Drawer = ({
  shouldScaleBackground = true,
  ...props
}: React.ComponentProps<typeof DrawerPrimitive.Root>) => <DrawerContext.Provider value={{
  direction: props.direction
}}>
    <DrawerPrimitive.Root shouldScaleBackground={shouldScaleBackground} {...props} />
  </DrawerContext.Provider>;
Drawer.displayName = 'Drawer';
const DrawerTrigger = DrawerPrimitive.Trigger;
const DrawerPortal = DrawerPrimitive.Portal;
const DrawerClose = DrawerPrimitive.Close;
const DrawerOverlay = React.forwardRef<React.ElementRef<typeof DrawerPrimitive.Overlay>, React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Overlay>>(({
  className,
  ...props
}, ref) => <DrawerPrimitive.Overlay ref={ref} className={cn('fixed inset-0 z-50 bg-black/80', className)} {...props} />);
DrawerOverlay.displayName = DrawerPrimitive.Overlay.displayName;
const DrawerBottomNotch = () => <div className="mx-auto mt-4 h-2 w-[100px] rounded-full bg-zinc-100" />;
const classes = {
  content: {
    bottom: 'rounded-t-[10px] inset-x-0 bottom-0 mt-24',
    right: 'top-0 right-0 w-screen max-w-full sm:max-w-[75%] h-full'
  }
};
const DrawerContent = React.forwardRef<React.ElementRef<typeof DrawerPrimitive.Content>, React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Content>>(({
  className,
  children,
  ...props
}, ref) => {
  const {
    direction
  } = useDrawerContext();
  const isDirectionBottom = !direction || direction === 'bottom';
  return <DrawerPortal>
      <DrawerOverlay />
      <DrawerPrimitive.Content ref={ref} className={cn('fixed z-50 flex h-auto flex-col border border-zinc-200 bg-white p-10', {
      [classes.content.bottom]: isDirectionBottom,
      [classes.content.right]: direction === 'right'
    }, className)} {...props}>
        {isDirectionBottom && <DrawerBottomNotch />}
        {children}
      </DrawerPrimitive.Content>
    </DrawerPortal>;
});
DrawerContent.displayName = 'DrawerContent';
const DrawerHeader = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => <div className={cn('grid gap-1.5 mb-10 text-center sm:text-left', className)} {...props} />;
DrawerHeader.displayName = 'DrawerHeader';
const DrawerFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => <div className={cn('mt-auto flex flex-col gap-2 p-4', className)} {...props} />;
DrawerFooter.displayName = 'DrawerFooter';
const DrawerTitle = React.forwardRef<React.ElementRef<typeof DrawerPrimitive.Title>, React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Title>>(({
  className,
  ...props
}, ref) => <DrawerPrimitive.Title ref={ref} className={cn('text-xl font-heading font-bold leading-none', className)} {...props} />);
DrawerTitle.displayName = DrawerPrimitive.Title.displayName;
const DrawerDescription = React.forwardRef<React.ElementRef<typeof DrawerPrimitive.Description>, React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Description>>(({
  className,
  ...props
}, ref) => <DrawerPrimitive.Description ref={ref} className={cn('text-sm text-zinc-500', className)} {...props} />);
DrawerDescription.displayName = DrawerPrimitive.Description.displayName;
export { Drawer, DrawerPortal, DrawerOverlay, DrawerTrigger, DrawerClose, DrawerContent, DrawerHeader, DrawerFooter, DrawerTitle, DrawerDescription };