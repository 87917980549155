'use client';

import { AnimatePresence, motion } from 'framer-motion';
import { CookieIcon } from 'lucide-react';
import Link from 'next/link';
import { setConsent } from '@/app/actions';
import { Button } from '@/components/ui/button';
interface ConsentProps {
  consent?: boolean;
}
export const Consent = ({
  consent
}: ConsentProps) => {
  return <AnimatePresence>
      {consent === undefined && <motion.div initial={{
      opacity: 0
    }} animate={{
      opacity: 1
    }} exit={{
      opacity: 0
    }} style={{
      zIndex: 200,
      left: '50%',
      bottom: '10rem',
      transform: 'translateX(-50%)'
    }} className="fixed w-full max-w-xl shadow-xl">
          <div className="border-2 border-secondary-readable/[0.25] bg-background rounded-md">
            <div className="grid gap-2">
              <div className="border-b border-border h-14 flex items-center justify-between p-4">
                <h1 className="text-lg font-[800]">Cookie Consent</h1>
                <CookieIcon className="h-[1.2rem] w-[1.2rem] text-primary" />
              </div>
              <div className="px-4 space-y-4">
                <p>
                  We use cookies to improve your experience on our site. Your
                  privacy matters to us, and we comply with data protection
                  laws. Visit our{' '}
                  <Link prefetch={false} href="/privacyPolicy">
                    Privacy Policy
                  </Link>{' '}
                  for details.
                </p>
                <ul>
                  <li>Use the Accept button to consent.</li>
                  <li>Use the Reject button to continue without accepting.</li>
                </ul>
              </div>
              <div className="flex gap-2 p-4 py-5 border-t border-border bg-background/20">
                <Button onClick={async () => await setConsent(true)} className="w-full">
                  Accept
                </Button>
                <Button onClick={async () => await setConsent(false)} className="w-full" variant="outline">
                  Reject
                </Button>
              </div>
            </div>
          </div>
        </motion.div>}
    </AnimatePresence>;
};