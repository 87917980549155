import * as React from 'react';

/**
 * Run media queries
 * @param query css media query
 * @example const isDesktop = useMediaQuery("(min-width: 768px)")
 * @returns
 */
export function useMediaQuery(query: string) {
  const [value, setValue] = React.useState<boolean | null>(null);
  React.useEffect(() => {
    function onChange(event: MediaQueryListEvent) {
      setValue(event.matches);
    }
    const result = matchMedia(query);
    result.addEventListener('change', onChange);
    setValue(result.matches);
    return () => result.removeEventListener('change', onChange);
  }, [query]);
  return value;
}