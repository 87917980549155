'use client';

import { Lock, UnlockIcon } from 'lucide-react';
import { useRouter } from 'next/navigation';
import { Button } from '../ui/button';
import { InfoAlert } from './InfoAlert';
export const LoginAlert = () => {
  const router = useRouter();
  return <InfoAlert icon={<Lock />} title="Login Required">
      <>
        <p>
          This feature is only available to registered users. Registration is
          free, and once you sign up, you&apos;ll have free access to the
          datasets.
        </p>
        <Button variant="default" size="sm" onClick={() => router.push(`/api/auth/login?returnTo=${window.location.href}`)}>
          <UnlockIcon /> login or signup
        </Button>
      </>
    </InfoAlert>;
};