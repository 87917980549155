'use client';

import { UserProfile } from '@auth0/nextjs-auth0/client';
import { LogOut, UserIcon } from 'lucide-react';
import { useRouter } from 'next/navigation';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import { Popover, PopoverClose, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { Separator } from '@/components/ui/separator';
import { getNicknameInitials } from '@/lib/user';
export const User = ({
  user
}: {
  user: UserProfile;
}) => {
  const router = useRouter();
  const fallback = getNicknameInitials(user.nickname) ?? <UserIcon />;
  const picture = user.picture ? `${user.picture}&s=40` : undefined;
  return <Popover>
      <PopoverTrigger>
        <Avatar>
          <AvatarImage alt={user?.name ?? 'User Avatar'} src={picture} />
          <AvatarFallback>{fallback}</AvatarFallback>
        </Avatar>
      </PopoverTrigger>
      <PopoverContent>
        <div className="flex flex-col justify-center items-center space-y-4">
          <div className="font-mono text-sm">{user.email}</div>

          <Separator />

          <div className="flex gap-5">
            <PopoverClose asChild>
              <Button size="sm" variant="outline" onClick={() => router.push('/user/profile')}>
                <UserIcon className="w-4 h-4" /> Profile
              </Button>
            </PopoverClose>
            <PopoverClose asChild>
              <Button size="sm" variant="destructive" onClick={() => router.push('/api/auth/logout')}>
                <LogOut className="w-4 h-4" /> Logout
              </Button>
            </PopoverClose>
          </div>
        </div>
      </PopoverContent>
    </Popover>;
};